import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  //Actualizar la contraseña
  {
    path: '/app/config/micontrasenia',
    name: 'cambiarcontra',
    component: () => import('@/views/usuarios/CambiarContraseniaView.vue')
  },
  //Exportar datos
  {
    path: '/app/reportes/exporta',
    name: 'exportardatos',
    component: () => import('@/views/reportes/ExportarInformacionView.vue')
  },
  //Listado de acciones de gobierno
  {
    path: '/app/gestionterritorial/accionesdegobierno/registro',
    name: 'registroaccionesgobierno',
    component: () => import('@/views/gestionTerritorial/accionesdegobierno/RegsitrarAccionDeGobierno.vue')
  },

  //Listado de acciones de gobierno
  {
    path: '/app/gestionterritorial/accionesdegobierno/listado',
    name: 'listadoaccionesgobierno',
    component: () => import('@/views/gestionTerritorial/accionesdegobierno/ListadoAccionesGobiernoView.vue')
  },


  //detalle de la problemática registrada
  {
    path: '/app/gestionterritorial/problematica/detalle',
    name: 'detalleproblematicaregistrada',
    component: () => import('@/views/gestionTerritorial/problematica/DetallesProblematicaView.vue')
  },

  //Listado de tipos de liderazgos
  {
    path: '/app/catalogos/liderazgos',
    name: 'admintiposliderazgo',
    component: () => import('@/views/catalogos/ListadoTiposLiderazgoView.vue')
  },
  //Listado de apoyos
  {
    path: '/app/catalogos/apoyos',
    name: 'admintiposapoyos',
    component: () => import('@/views/catalogos/ListadoTiposApoyosView.vue')
  },
  //Listado de ocupaciones
  {
    path: '/app/catalogos/ocupaciones',
    name: 'adminocupaciones',
    component: () => import('@/views/catalogos/ListadoOcupacionesView.vue')
  },
  //Administración de usuarios
  {
    path: '/app/usuarios',
    name: 'adminusuarios',
    component: () => import('@/views/usuarios/UsuariosView.vue')
  },
  //Registrar un nuevo usuario
  {
    path: '/app/usuarios/registrar',
    name: 'nuevousuario',
    component: () => import('@/views/usuarios/RegistrarUsuarioView.vue')
  },
  //Lista de liderazgos
  {
    path: '/app/gestionterritorial/liderazgo/lista',
    name: 'listaliderazgos',
    component: () => import('@/views/gestionTerritorial/liderazgos/ListaLiderazgosView.vue')
  },
  //Registrar un nuevo liderazgo
  {
    path: '/app/gestionterritorial/liderazgo/nuevo',
    name: 'gtregistranuevoliderazgo',
    component: () => import('@/views/gestionTerritorial/liderazgos/RegistroLiderazgoView.vue')
  },
  //Ver la lista de identifiación de problemáticas registradas por un usuario  
  {
    path: '/app/gestionterritorial/problematica/lista',
    name: 'gtlistaproblematicasusuarip',
    component: () => import('@/views/gestionTerritorial/problematica/ListadoProblematicasRegistradasView.vue')
  },
  //Ver la lista de apoyos entregados por unidad de territorio
  {
    path: '/app/gestionterritorial/apoyos/lista',
    name: 'gtlistaapoyos',
    component: () => import('@/views/gestionTerritorial/apoyos/ListaApoyosEntregadosTerritorioView.vue')
  },
  //Registrar un nuevo apoyo para una persona
  {
    path: '/app/gestionterritorial/apoyos/nuevo',
    name: 'gtregistranuevoapoyo',
    component: () => import('@/views/gestionTerritorial/apoyos/RegistroApoyoView.vue')
  },
  //Poner en verde una identificación de problemática
  {
    path: '/app/gestionterritorial/finalizaaspectoidentifica',
    name: 'finalizaaspectp',
    component: () => import('@/views/gestionTerritorial/problematica/FinalizaIdentificaAspectoView.vue')
  },
  //Ver causas raiz con territorio
  {
    path: '/app/gestionterritorial/causasraiz',
    name: 'gesterritoriocausaraiz',
    component: () => import('@/views/gestionTerritorial/problematica/VerProblematicasConTerritoriosView.vue')
  },
  //Listado de todas las problemáticas registradas
  {
    path: '/app/gestionterritorial/problemativas/listado',
    name: 'listadoproblematicasregistradas',
    component: () => import('@/views/gestionTerritorial/problematica/ListadoProblematicasRegistradasView.vue')
  },
  //Información del territorio
  {
    path: '/app/gestionterritorial/semaforos',
    name: 'semaforosterritorio',
    component: () => import('@/views/gestionTerritorial/problematica/ListadoProblematicasActualesPorTerritorioView.vue')
  },
  //Ver el historial (no le muevas a esta url, meta la usa para el botón del whatsapp)
  {
    path: '/peticion/historia/:clavePeticion',
    name: 'historiapeticion',
    component: () => import(/* webpackChunkName: "about" */ '@/views/public/peticiones/HistorialPeticionView.vue')
  },
  //ver el tablero de territorio que muestra el número de aspectos y su prioridad
  {
    path: '/app/dashboard/aspectos/territorio',
    name: 'territorioaspectos',
    component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/TabletoAspectosPrioridadPorTerritorio.vue')
  },
  {
    path: '/app/dashboard/aspectos',
    name: 'dbdaspectos',
    component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/TabletoAspectosPrioridad.vue')
  },
  //ver el reporte de métricas por nodo de la organización
  {
    path: '/app/dashboard/nodos/metricas',
    name: 'nodosmetricas',
    //component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/MetricasNodosOrganizacionView.vue')
    component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/DashboardGlobal.vue')
  },
  //Editar evento
  {
    path: '/app/eventos/editar',
    name: 'eventoeditar',
    component: () => import(/* webpackChunkName: "about" */ '@/views/eventos/AgregarEditarEventoView.vue')
  },
  //Crear evento
  {
    path: '/app/eventos/crear',
    name: 'eventoscrear',
    component: () => import(/* webpackChunkName: "about" */ '@/views/eventos/AgregarEditarEventoView.vue')
  },
  //Identificación de un aspecto (problematica)
  {
    path: '/app/gestionterritorial/creavariable',
    name: 'identificaaspecto',
    component: () => import(/* webpackChunkName: "about" */ '@/views/gestionTerritorial/problematica/RegistroIdentificacionProblematicaView.vue')
  },
  //Ver las peticiones asignadas
  {
    path: '/app/bandeja',
    name: 'bandejaentrada',
    component: () => import(/* webpackChunkName: "about" */ '@/views/BandejaEntradaView.vue')
  },
  //Detalle de la petición
  {
    path: '/app/peticion/detalle',
    name: 'detallepeticion',
    component: () => import(/* webpackChunkName: "about" */ '@/views/peticiones/DetallePeticionView.vue')
  },
  // ///Registro de un usuario
  // {
  //   path: '/app/registrarse',
  //   name: 'registrarusuario',
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/usuarios/AutoRegistroUsuarioView.vue')
  // },
  //problemáticas
  {
    path: '/app/catalogos/problematica',
    name: 'adminproblematica',
    component: () => import(/* webpackChunkName: "about" */ '@/views/catalogos/EstructuraProblematicasView.vue')
  },


  //Clasificaciones de acciones de gobierno
  {
    path: '/app/catalogos/accionesgobierno',
    name: 'adminaccionesgobierno',
    component: () => import(/* webpackChunkName: "about" */ '@/views/catalogos/EstructuraClasificacionAccionesGobierno.vue')
  },


  //Ubicaciones
  {
    path: '/app/catalogos/estucturaterritorial',
    name: 'admubicaciones',
    component: () => import(/* webpackChunkName: "about" */ '@/views/catalogos/EstructuraTerritorialView.vue')
  },
  ///Estructura de la organizacion
  {
    path: '/app/catalogos/estructura',
    name: 'estructuraorganizacion',
    component: () => import(/* webpackChunkName: "about" */ '@/views/catalogos/EstructuraOrganizacionView.vue')
  },
  ///Agregar una nueva petición
  {
    path: '/app/organizacion/evento/agregapeticion',
    name: 'agregapeticionevento',
    component: () => import(/* webpackChunkName: "about" */ '@/views/peticiones/PeticionView.vue')
  },
  ///Detalles de un evento
  {
    path: '/app/organizacion/evento/:claveEvento',
    name: 'verdetalleevento',
    component: () => import(/* webpackChunkName: "about" */ '@/views/eventos/DetalleEventoView.vue')
  },
  ///Eventos dentro de una organización
  {
    path: '/app/eventos',
    name: 'vereventos',
    component: () => import(/* webpackChunkName: "about" */ '@/views/eventos/ListaEventosView.vue')
  },
  {
    path: '/app/misorganizaciones',
    name: 'misorganizaciones',
    component: () => import(/* webpackChunkName: "about" */ '@/views/MisOrganicacionesView.vue')
  },
  {
    path: '/:nombreinstancia',
    name: 'inicioSesion',
    component: () => import('@/views/LoginView.vue')
  },

  {
    path: '/',
    name: 'inicioSesion',
    component: () => import('@/views/LoginView.vue')
  },

]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
