import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nombreDeUsuario: "",
    haIniciadoSesion: false,
    drawer: true,
    esMovil: true,
    tituloPagina: "PAC"
  },
  getters: {
  },
  mutations: {
    UPDATE_DRAWER_STATE(state, newDrawerState) {
      state.drawer = newDrawerState;
    },
    UPDATE_ESMOVIL_STATE(state, esMovil) {
      state.esMovil = esMovil;
    },
    //Establecer el título de la página
    setTituloPagina(state, nuevoTitulo) {
      state.tituloPagina = nuevoTitulo;
      document.title = "PAC - " + nuevoTitulo;
    },
    cerrarSesion(state) {
      state.haIniciadoSesion = false;
      state.nombreDeUsuario = "";
      localStorage.removeItem("jwt");
      localStorage.clear();
    },
    setInicioSesionCorrecto(state, res) {
      localStorage.nombreUsuario = res.nombreUsuario;
      localStorage.nombreOrganizacion = res.nombreOrganizacion;
      localStorage.jwt = res.jwt;
      this.commit("recupera")
    },
    recupera(state) {
      state.nombreDeUsuario = localStorage.nombreUsuario;
      state.haIniciadoSesion = true;
    }
  },
  actions: {
  },
  modules: {
  }
})
