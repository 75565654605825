class UrlTools {
  convierte(params) {
    return params
      .map(obj => {
        const key = Object.keys(obj)[0];  // Obtener el nombre del primer (y único) campo del objeto
        const value = obj[key];           // Obtener el valor asociado
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');  // Unir los pares clave-valor con "&"
  }

  construyeUrl(url, params) {
    var param = this.convierte(params);
    return url + "?" + param;
  }
}


export default new UrlTools();