<template>
  <div>

    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app >
      
      <v-list>
        <!-- <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
          </v-list-item-avatar>
        </v-list-item> -->

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-caption">
              {{ nombreUsuario }}
            </v-list-item-title> 
            <v-list-item-subtitle>
              {{ nombreOrganizacion }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div v-for="(mnu, index) in losMenus" :key="index">
        <p class="ma-3 mb-0 font-weight-bold">{{ mnu.text }}</p>

        <v-list dense>
          <template v-for="item in mnu.children">
            <v-list-group v-if="item.children" :key="item.text" v-model="item.model" :prepend-icon="item.icon"
              append-icon="">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>
                      {{ item.text }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="(child, i) in item.children" :key="i" :to="{ name: child.to }" link>
                <v-list-item-action v-if="child.icon">
                  <v-icon class="ml-4">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>
                      {{ child.text }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-item v-else :key="item.text" :to="{ name: item.to }" link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>



      </div>


      <v-divider></v-divider>
      <!-- Alternar tema -->
      <v-list dense>
        <v-list-item-group>
          <v-list-item @click="cambiaTema" link>
            <v-list-item-icon>
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span> Alternar tema </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-item-group>
      </v-list>


    </v-navigation-drawer>
  </div>
</template>
<script>
import acapi from "@/services/api.atencion.ciudadana.js";
import Vuex from "vuex";
import mixin from "@/mixins/mixinpermisos.js";
export default {
  name: "NavigationDrawer",
  mixins: [mixin],
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(newDraweState) {
        this.UPDATE_DRAWER_STATE(newDraweState);
        return newDraweState;
      },
    },
  },

  data: () => ({
    losMenus: [],
    nombreUsuario: "Nombre del usuario",
    nombreOrganizacion: "Nombre de la organización"
  }),
  methods: {
    ...Vuex.mapMutations(["UPDATE_DRAWER_STATE"]),
    cambiaTema() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("isDark", this.$vuetify.theme.dark);
    },
    async leerMenu() {
      this.loading = true;
      this.errored = false;
      await acapi
        .getMenuUsuario()
        .then((response) => {
          this.losMenus = response.data.data;
        })
        .catch((error) => {
          this.mensajeError = error;
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    var dark = localStorage.getItem("isDark");
    if (dark !== undefined) this.$vuetify.theme.dark = dark;
    this.leerMenu();
    this.nombreUsuario = localStorage.nombreUsuario;
    this.nombreOrganizacion = localStorage.nombreOrganizacion;
  },
};
</script>
