import VueJwtDecode from 'vue-jwt-decode'
import permisos from "@/services/permisos";

export default {
    computed: {
        esAdministrador: function () {
            var jwt = localStorage.getItem("jwt");
            var valoresjwt = VueJwtDecode.decode(jwt);
            console.log(valoresjwt);
            return valoresjwt.perfil == 0;
        }
    },
    methods: {
        tienePermisoDe(idSubModuloOperacion) {                        
            return permisos.tienePermisoOperacion(idSubModuloOperacion);
        },
    }
}