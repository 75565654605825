<!-- eslint-disable -->

<template>
  <v-app id="inspire">
    <NavigationDrawer v-if="haIniciadoSesion" />
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark>
      <v-app-bar-nav-icon v-if="haIniciadoSesion" @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="ml-0 pl-4">
        <span v-if="!nombreSuscriptor" class="hidden-sm-and-down"
          >PAC</span
        >
        <span v-else class="hidden-sm-and-down">{{ tituloPagina }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text v-if="haIniciadoSesion" @click="dialogoCerrarSesion = true">
        Cerrar sesion
      </v-btn>
    </v-app-bar>
    <v-dialog v-model="dialogoCerrarSesion">
      <v-card>
        <v-card-title>Cerrar sesión</v-card-title>
        <v-card-text>¿Está seguro de cerrar sesión en el sistema?</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="dialogoCerrarSesion = false"
            >No, cancelar</v-btn
          >
          <v-btn color="primary" @click="doCerrarSesion">Sí, cerrar sesión</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main>
      <router-view class="pa-2" />
    </v-main>
    <p class="pa-5 text-caption mb-10">Versión 1.18 b</p>
  </v-app>
</template>

<script>
import NavigationDrawer from "@/components/menu/NavigationDrawer.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "App",
  components: {
    NavigationDrawer,
  },
  computed: {
    ...mapState(["haIniciadoSesion", "esMovil", "tituloPagina"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(newDraweState) {
        this.UPDATE_DRAWER_STATE(newDraweState);
        return newDraweState;
      },
    },
  },
  data() {
    return {
      dialogoCerrarSesion: false,
      nombreSuscriptor: "Registro de peticiones",
    };
  },
  methods: {
    ...mapMutations([
      "recupera",
      "cerrarSesion",
      "UPDATE_DRAWER_STATE",
      "UPDATE_ESMOVIL_STATE",
    ]),
    doCerrarSesion() {
      var ni = localStorage.nombreInstancia;
      console.log("nns", ni);
      this.$router.push({ name: "inicioSesion", params: { nombreinstancia: ni } });
      this.cerrarSesion();
      this.dialogoCerrarSesion = false;            
    },
    onResize() {
      this.UPDATE_ESMOVIL_STATE(window.innerWidth < 600);
    },
  },
  mounted() {
    if (localStorage.jwt) {
      this.recupera();
    }
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
